import React, { useEffect, useState } from "react";
import Diagram, {
  Nodes,
  AutoLayout,
  Edges,
  Toolbox,
  Group,
} from "devextreme-react/diagram";
import ArrayStore from "devextreme/data/array_store";
import { ownStore } from "../../../AppOwnState/ownState";
import { ChangePageDataSetState } from "../../../General/commonFunctions";



export const FactsDevDiagram = (props) => {


    const getCurrentDataSet = () => {
      var _listData = ChangePageDataSetState([props.listdset]);
      if (!_listData) return null;
      _listData = _listData[props.listdset];
      return _listData;
    };

    const [data, setData] = useState(getCurrentDataSet());

    useEffect(() => {
      let unsubscribe = ownStore.subscribe((storeInfo) => {
        if (props.listdset === storeInfo.dset || props.listdset === "this") {
          let newDS = getCurrentDataSet();
          setData(newDS);
        }
      });
      return () => {
        if (unsubscribe) unsubscribe();
      };
    }, []);


    const orgDatadataSource = new ArrayStore({
      key: "id",
      data: data,
    });

    const orgLinksdataSource = new ArrayStore({
      key: "id",
      data: data,
    });


    
  return (
    <Diagram id="diagram" {...props}>
      <Nodes dataSource={orgDatadataSource}>
        <AutoLayout orientation="horizontal" type="tree" />
      </Nodes>
      <Edges dataSource={orgLinksdataSource} />
      <Toolbox>
        <Group category="general" title="General" />
        <Group
          category="orgChart"
          title="Organizational Chart"
          expanded={true}
        />
      </Toolbox>
    </Diagram>
  );
}
